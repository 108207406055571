































































































































































































import { defineComponent, computed, onMounted, reactive } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import SfSelectOption from '@storefront-ui/vue/src/components/molecules/SfSelect/_internal/SfSelectOption.vue';
import { SfModal } from '@storefront-ui/vue';
import PrimaryInput from '~/components/atoms/Inputs/PrimaryInput/PrimaryInput.vue';
import {
  SALESFORCE_WEBFORM_FIELD,
  SALESFORCE_FORM_SUBMIT_URL,
  SALESFORCE_WEBFORM_REASONS,
  SALESFORCE_FORM_INDICATOR,
  SALESFORCE_DYNAMIC_FIELDS
} from '~/constants/salesforce';
import Select from '~/components/atoms/Inputs/Select/Select.vue';
import CustomTextarea from '~/components/atoms/Inputs/CustomTextarea/CustomTextarea.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { useI18n } from '~/composables';
import { SalesforceWebformReason, SalesforceWebformSubreason } from '~/types/salesforce';
import { salesforceWebformLocales } from '~/i18n';

type SalesforceFormIndicatorValue = ValueOf<typeof SALESFORCE_FORM_INDICATOR>;
type SalesforceDynamicFieldValue = ValueOf<typeof SALESFORCE_DYNAMIC_FIELDS>;

export default defineComponent({
  name: 'SaleforceWebform',
  components: {
    SfModal,
    PrimaryInput,
    Select,
    SfSelectOption,
    CustomTextarea,
    Button
  },
  props: {
    onClose: {
      type: Function,
      required: true
    }
  },
  setup () {
    const { countryLabel, language, languageAndCountry } = useI18n();

    const salesforceWebformData = reactive<{
      selectedReasonIndicator: SalesforceFormIndicatorValue,
      selectedSubreasonIndicator: SalesforceFormIndicatorValue,
      selectedReason: SalesforceWebformReason | null,
      selectedSubreason: SalesforceWebformSubreason | null,
      locale: ValueOf<typeof salesforceWebformLocales>,
      description: string,
      contactName: string,
      companyName: string,
      customerEmail: string,
      subject: string,
      orderNumber: string,
      productNumber: string,
      invoiceNumber: string,
      currentUrl: string}>(
        {
          selectedReasonIndicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE,
          selectedSubreasonIndicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE,
          selectedReason: null,
          selectedSubreason: null,
          locale: salesforceWebformLocales[language.value],
          description: '',
          contactName: '',
          companyName: '',
          customerEmail: '',
          subject: '',
          orderNumber: '',
          productNumber: '',
          invoiceNumber: '',
          currentUrl: ''
        }
      );

    const selectedReasonSubreasons: ComputedRef<SalesforceWebformSubreason[]> = computed(() => {
      return SALESFORCE_WEBFORM_REASONS.find(
        reason => reason.indicator === salesforceWebformData.selectedReasonIndicator
      )?.subreasons || [];
    });

    const updateSelectedReasonIndicator = (selectedValue: SalesforceFormIndicatorValue) => {
      salesforceWebformData.selectedReason = SALESFORCE_WEBFORM_REASONS.find(reason =>
        SALESFORCE_WEBFORM_FIELD[reason.indicator] === selectedValue
      );
      salesforceWebformData.selectedReasonIndicator = salesforceWebformData.selectedReason
        ? salesforceWebformData.selectedReason?.indicator
        : '';
    };

    const updateSelectedSubreasonIndicator = (selectedValue: SalesforceFormIndicatorValue) => {
      salesforceWebformData.selectedSubreason = salesforceWebformData.selectedReason.subreasons?.find(subreason =>
        SALESFORCE_WEBFORM_FIELD[subreason.indicator] === selectedValue
      );
      salesforceWebformData.selectedSubreasonIndicator = salesforceWebformData.selectedSubreason
        ? salesforceWebformData.selectedSubreason?.indicator
        : '';
    };

    const getFieldVisibility = (field: SalesforceDynamicFieldValue): ComputedRef<boolean> => {
      return computed(() => {
        return selectedReasonSubreasons.value.find(subreason =>
          subreason.indicator === salesforceWebformData.selectedSubreason?.indicator
        )?.[field] || false;
      });
    };

    const shouldShowProductNumberField = getFieldVisibility(SALESFORCE_DYNAMIC_FIELDS.NEEDS_PRODUCT_NUMBER);
    const shouldShowInvoiceNumberField = getFieldVisibility(SALESFORCE_DYNAMIC_FIELDS.NEEDS_INVOICE_NUMBER);
    const shouldShowOrderNumberField = getFieldVisibility(SALESFORCE_DYNAMIC_FIELDS.NEEDS_ORDER_NUMBER);

    onMounted(() => {
      salesforceWebformData.currentUrl = typeof window === 'object' ? window.location.href : '';
    });

    return {
      SALESFORCE_FORM_SUBMIT_URL,
      SALESFORCE_WEBFORM_REASONS,
      SALESFORCE_WEBFORM_FIELD,
      selectedReasonSubreasons,
      updateSelectedReasonIndicator,
      updateSelectedSubreasonIndicator,
      salesforceWebformData,
      countryLabel,
      shouldShowProductNumberField,
      shouldShowOrderNumberField,
      shouldShowInvoiceNumberField,
      language,
      languageAndCountry
    };
  }
});
