import { SalesforceWebformReason } from '~/types/salesforce';

export const SALESFORCE_FORM_INDICATOR = {
  SALESFORCE_REASON_NONE: '',
  SALESFORCE_REASON_CONSULTING: 'salesforce-reason-consulting',
  SALESFORCE_REASON_PAYMENT: 'salesforce-reason-payment',
  SALESFORCE_REASON_SALES: 'salesforce-reason-sales',
  SALESFORCE_REASON_ORDER: 'salesforce-reason-order',
  SALESFORCE_REASON_SHIPMENT: 'salesforce-reason-shipment',
  SALESFORCE_REASON_INVOICE: 'salesforce-reason-invoice',
  SALESFORCE_REASON_PRODUCT_CLAIM: 'salesforce-reason-product-claim',
  SALESFORCE_REASON_RETURNS: 'salesforce-reason-returns',
  SALESFORCE_REASON_ACCOUNT: 'salesforce-reason-account',
  SALESFORCE_REASON_SHOP_ISSUE: 'salesforce-reason-shop-issue',
  SALESFORCE_REASON_OTHER: 'salesforce-reason-other',
  SALESFORCE_SUBREASON_QUESTION_ABOUT_PRODUCT: 'salesforce-subreason-question-about-product',
  SALESFORCE_SUBREASON_AVAILABILITY: 'salesforce-subreason-availability',
  SALESFORCE_SUBREASON_MISSING_DATA: 'salesforce-subreason-missing-data',
  SALESFORCE_SUBREASON_WARRANTY_RETURN: 'salesforce-subreason-warranty-return',
  SALESFORCE_SUBREASON_CERTIFICATE_REQUESTS: 'salesforce-subreason-certificate-requests',
  SALESFORCE_SUBREASON_DELIVERY_INFO: 'salesforce-subreason-delivery-info',
  SALESFORCE_SUBREASON_FACTORY_SECOND: 'salesforce-subreason-factory-seconds',
  SALESFORCE_SUBREASON_PAYMENT_METHODS: 'salesforce-subreason-payment-methods',
  SALESFORCE_SUBREASON_DOUBLE_PAYMENT: 'salesforce-subreason-double-payment',
  SALESFORCE_SUBREASON_PAYMENT_PROOF: 'salesforce-subreason-payment-proof',
  SALESFORCE_SUBREASON_REFUND: 'salesforce-subreason-refund',
  SALESFORCE_SUBREASON_PAYMENT_STATUS: 'salesforce-subreason-payment-status',
  SALESFORCE_SUBREASON_PAYMENT_ISSUES: 'salesforce-subreason-payment-issues',
  SALESFORCE_SUBREASON_INFO_IBAN: 'salesforce-subreason-info-iban',
  SALESFORCE_SUBREASON_DISCOUNT_REQUEST: 'salesforce-subreason-discount-request',
  SALESFORCE_SUBREASON_PROFORMA_REQUEST: 'salesforce-subreason-proforma-request',
  SALESFORCE_SUBREASON_SPARE_PARTS_REQUEST: 'salesforce-subreason-spare-parts-request',
  SALESFORCE_SUBREASON_DISCOUNT_CODE_ISSUE: 'salesforce-subreason-discount-code-issue',
  SALESFORCE_SUBREASON_DISCOUNT_CODE_MISSING: 'salesforce-subreason-discount-code-missing',
  SALESFORCE_SUBREASON_ORDER_STATUS: 'salesforce-subreason-order-status',
  SALESFORCE_SUBREASON_CHANGE_ORDER: 'salesforce-subreason-change-order',
  SALESFORCE_SUBREASON_CANCELLATION_REQUEST: 'salesforce-subreason-cancellation-request',
  SALESFORCE_SUBREASON_SHIPMENT_STATUS: 'salesforce-subreason-shipment-status',
  SALESFORCE_SUBREASON_MISSING_TRACKING: 'salesforce-subreason-missing-tracking',
  SALESFORCE_SUBREASON_NO_SHIPPING_PROGRESS: 'salesforce-subreason-no-shipping-progress',
  SALESFORCE_SUBREASON_LOSS_REPORT: 'salesforce-subreason-loss-report',
  SALESFORCE_SUBREASON_CHANGE_DELIVERY_DATE: 'salesforce-subreason-change-delivery-date',
  SALESFORCE_SUBREASON_CHANGE_DELIVERY_INFO: 'salesforce-subreason-change-delivery-info',
  SALESFORCE_SUBREASON_INVOICE_REQUEST: 'salesforce-subreason-invoice-request',
  SALESFORCE_SUBREASON_CHANGE_DATA: 'salesforce-subreason-change-data',
  SALESFORCE_SUBREASON_NET_INVOICE: 'salesforce-subreason-net-invoice',
  SALESFORCE_SUBREASON_INVOICE_ISSUE: 'salesforce-subreason-invoice-issues',
  SALESFORCE_SUBREASON_MALFUNCTION: 'salesforce-subreason-malfunction',
  SALESFORCE_SUBREASON_INCOMPLETE_PRODUCT: 'salesforce-subreason-incomplete-product',
  SALESFORCE_SUBREASON_WRONG_DESCRIPTION: 'salesforce-subreason-wrong-description',
  SALESFORCE_SUBREASON_TRANSIT_DAMAGE: 'salesforce-subreason-transit-damage',
  SALESFORCE_SUBREASON_COSMETIC_ISSUE: 'salesforce-subreason-cosmetic-issue',
  SALESFORCE_SUBREASON_MISSING_MANUAL: 'salesforce-subreason-missing-manual',
  SALESFORCE_SUBREASON_MISSING_LANGUAGE_MANUAL: 'salesforce-subreason-missing-language-manual',
  SALESFORCE_SUBREASON_MISSING_DETAIL_MANUAL: 'salesforce-subreason-missing-detail-manual',
  SALESFORCE_SUBREASON_BAD_APPEARANCE: 'salesforce-subreason-bad-appearance',
  SALESFORCE_SUBREASON_WRONG_PRODUCT: 'salesforce-subreason-wrong-product',
  SALESFORCE_SUBREASON_REPAIR_STATUS: 'salesforce-subreason-repair-status',
  SALESFORCE_SUBREASON_BAD_PERFORMANCE: 'salesforce-subreason-bad-performance',
  SALESFORCE_SUBREASON_NO_REASON: 'salesforce-subreason-no-reason',
  SALESFORCE_SUBREASON_PORTAL_ISSUE: 'salesforce-subreason-portal-issue',
  SALESFORCE_SUBREASON_LOGIN_ISSUE: 'salesforce-subreason-login-issue',
  SALESFORCE_SUBREASON_DATA_REMOVAL: 'salesforce-subreason-data-removal',
  SALESFORCE_SUBREASON_ORDER_PAGE: 'salesforce-subreason-order-page',
  SALESFORCE_SUBREASON_WEBPAGE_DOWN: 'salesforce-subreason-webpage-down',
  SALESFORCE_SUBREASON_ORDER_ISSUE: 'salesforce-subreason-order-issue',
  SALESFORCE_SUBREASON_NEUTRAL: 'salesforce-subreason-neutral',
  SALESFORCE_SUBREASON_MARKETING_REQUEST: 'salesforce-subreason-marketing-request',
  SALESFORCE_SUBREASON_JOB_APPLICATION: 'salesforce-subreason-job-application',
  SALESFORCE_SUBREASON_PARTNERSHIP: 'salesforce-subreason-partnership',
  SALESFORCE_SUBREASON_UNSUBSCRIBE: 'salesforce-subreason-unsubscribe'
};

export const SALESFORCE_WEBFORM_FIELD = {
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_CONSULTING]: 'Consulting',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_PAYMENT]: 'Payments',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SALES]: 'Sales',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_ORDER]: 'Order',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SHIPMENT]: 'Shipment',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_INVOICE]: 'Invoice',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_PRODUCT_CLAIM]: 'Product claim within 30 days',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_RETURNS]: 'Return',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_ACCOUNT]: 'Account',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SHOP_ISSUE]: 'Shop issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_OTHER]: 'Other',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_QUESTION_ABOUT_PRODUCT]: 'Question About the Product',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_AVAILABILITY]: 'Is it in stock?',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_DATA]: 'Missing product data',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WARRANTY_RETURN]: 'Warranty and return',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CERTIFICATE_REQUESTS]: 'Certificate requests',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DELIVERY_INFO]: 'Delivery info',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_FACTORY_SECOND]: 'Factory seconds',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_METHODS]: 'Payments Methods',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DOUBLE_PAYMENT]: 'Double payment',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_PROOF]: 'Payment proof',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_REFUND]: 'Refund',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_STATUS]: 'Payment status',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_ISSUES]: 'Payment issues',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INFO_IBAN]: 'Info IBAN payment',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_REQUEST]: 'Offer/Discount request',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PROFORMA_REQUEST]: 'Manual order/Proforma request',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_SPARE_PARTS_REQUEST]: 'Spare parts order request $',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_CODE_ISSUE]: 'Discount code issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_CODE_MISSING]: 'Discount code missing',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_ORDER_STATUS]: 'Missing order confirmation/Order status',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_ORDER]: 'Change order',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CANCELLATION_REQUEST]: 'Cancellation request',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_SHIPMENT_STATUS]: 'Status request/Shipment status',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_TRACKING]: 'Missing tracking link',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NO_SHIPPING_PROGRESS]: 'No shipment progress',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_LOSS_REPORT]: 'Lost report',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_DELIVERY_DATE]: 'Change delivery date',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_DELIVERY_INFO]:
    'Update delivery address (additional delivery info)',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INVOICE_REQUEST]: 'Invoice request',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_DATA]: 'Change data',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NET_INVOICE]: 'Net invoice',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INVOICE_ISSUE]:
    'Invoice challenges (invoice link not working, wrong price etc...)',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MALFUNCTION]: 'Malfunction',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INCOMPLETE_PRODUCT]: 'Incomplete product',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WRONG_DESCRIPTION]: 'Wrong product description',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_TRANSIT_DAMAGE]: 'Damaged in transit',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_COSMETIC_ISSUE]: 'Cosmetic issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_MANUAL]: 'Missing manual',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_LANGUAGE_MANUAL]: "Manual not in customer's language",
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_DETAIL_MANUAL]: 'Manual not detailed enough',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_BAD_APPEARANCE]: 'Dislike Appearence/Performance',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WRONG_PRODUCT]: 'Wrong product',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_REPAIR_STATUS]: 'Repair status',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_BAD_PERFORMANCE]: 'Dislike Appearence/Performance',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NO_REASON]: 'No reason provided/unclear',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PORTAL_ISSUE]: 'Return portal issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_LOGIN_ISSUE]: 'Log-in issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DATA_REMOVAL]: 'Data removal request',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_ORDER_PAGE]: 'Order page/Self-service page issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WEBPAGE_DOWN]: 'Webpage not accessible',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_ORDER_ISSUE]: 'Order issue',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NEUTRAL]: 'Neutral feedback',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MARKETING_REQUEST]: 'Marketing request',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_JOB_APPLICATION]: 'Job application',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PARTNERSHIP]: 'Partnership sales',
  [SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_UNSUBSCRIBE]: 'Unsubscribe Newsletter'
};

export const SALESFORCE_DYNAMIC_FIELDS = {
  NEEDS_PRODUCT_NUMBER: 'needsProductNumber',
  NEEDS_INVOICE_NUMBER: 'needsInvoiceNumber',
  NEEDS_ORDER_NUMBER: 'needsOrderNumber'
};

export const SALESFORCE_WEBFORM_REASONS: SalesforceWebformReason[] = [
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_CONSULTING,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_QUESTION_ABOUT_PRODUCT,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_AVAILABILITY,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_DATA,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WARRANTY_RETURN,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CERTIFICATE_REQUESTS,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DELIVERY_INFO,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_FACTORY_SECOND,
        needsProductNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_PAYMENT,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_METHODS,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DOUBLE_PAYMENT,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_PROOF,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_REFUND,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_STATUS,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PAYMENT_ISSUES,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INFO_IBAN
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SALES,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_REQUEST,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PROFORMA_REQUEST,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_SPARE_PARTS_REQUEST,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_CODE_ISSUE,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_CODE_MISSING
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_ORDER,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_ORDER_STATUS,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_ORDER,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CANCELLATION_REQUEST,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SHIPMENT,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_SHIPMENT_STATUS,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_TRACKING,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NO_SHIPPING_PROGRESS,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_LOSS_REPORT,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_DELIVERY_DATE,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_DELIVERY_INFO,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_INVOICE,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INVOICE_REQUEST,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_CHANGE_DATA,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NET_INVOICE,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INVOICE_ISSUE,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_PRODUCT_CLAIM,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MALFUNCTION,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_INCOMPLETE_PRODUCT,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WRONG_DESCRIPTION,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_TRANSIT_DAMAGE,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_COSMETIC_ISSUE,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_MANUAL,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_LANGUAGE_MANUAL,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_DETAIL_MANUAL,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_BAD_APPEARANCE,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WRONG_PRODUCT,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_REPAIR_STATUS,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_BAD_PERFORMANCE,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_RETURNS,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NO_REASON,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PORTAL_ISSUE,
        needsProductNumber: true,
        needsInvoiceNumber: true,
        needsOrderNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_ACCOUNT,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_LOGIN_ISSUE
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DATA_REMOVAL
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_ORDER_PAGE,
        needsOrderNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SHOP_ISSUE,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_WEBPAGE_DOWN
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_ORDER_ISSUE,
        needsProductNumber: true
      }
    ]
  },
  {
    indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_OTHER,
    subreasons: [
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_NEUTRAL
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MARKETING_REQUEST
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_JOB_APPLICATION
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_PARTNERSHIP,
        needsProductNumber: true
      },
      {
        indicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_UNSUBSCRIBE
      }
    ]
  }
];

const SALESFORCE_ENDPOINT = 'https://expondogmbh--spdev.sandbox.my.salesforce.com/servlet/servlet.WebToCase';
const SALESFORCE_URL_PARAMETERS = '?encoding=UTF-8&orgId=00DKG000000GyXY';
export const SALESFORCE_FORM_SUBMIT_URL = `${SALESFORCE_ENDPOINT}${SALESFORCE_URL_PARAMETERS}`;
